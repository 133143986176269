@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100..900&display=swap");

body {
  background-color: #0A0A0A;
  color: #eee;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  font-weight: 300;
}

.circular-progress-container {
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Sola hizalama */
}
button {
  transition: all 0.35s;
}

input,
textarea,
select {
  outline: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.slick-dots {
  display: none !important;
}

html {
  scroll-behavior: smooth;
}